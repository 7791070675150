var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.$style.root},[_c('SectionCard',{staticClass:"mb-0"},[_c('VTabs',{on:{"change":_vm.onTabChange},model:{value:(_vm.tabData),callback:function ($$v) {_vm.tabData=$$v},expression:"tabData"}},[_c('VTab',{key:"#users-organization",attrs:{"href":"#users-organization"}},[_vm._v("Пользователи подрядной организации")]),_c('VTab',{key:"#users-invite",attrs:{"href":"#users-invite"}},[_vm._v("Заявки от внешних пользователей")])],1),_c('VTabsItems',{staticClass:"mt-4",model:{value:(_vm.tabData),callback:function ($$v) {_vm.tabData=$$v},expression:"tabData"}},[_c('VTabItem',{key:"users-organization",attrs:{"value":"users-organization"}},[_c('AccountUsersList',{class:_vm.$style.orgList,attrs:{"headers":_vm.organisationListHeaders,"items":_vm.organizationList.items,"count":_vm.organizationList.navCount,"page":_vm.page,"size":_vm.size,"loading":_vm.loading}})],1),_c('VTabItem',{key:"users-invite",attrs:{"value":"users-invite"}},[_c('AccountUsersList',{attrs:{"headers":[
        { text: 'ID пользователя', value: 'user.id', alias: 'userId', sortable: true },
        { text: 'Дата создания', value: 'user.dateCreate', alias: 'dateCreate', sortable: true },
        { text: 'ФИО', value: 'user.fio', alias: 'fio', sortable: false },
        { text: 'Должность', value: 'user.position', alias: 'position', sortable: false },
        { text: 'Статус', value: 'status.title', alias: 'status', options: _vm.values(_vm.statuses), sortable: false, multiple: false },
      ],"items":_vm.inviteList.items,"count":_vm.inviteList.navCount,"page":_vm.page,"size":_vm.size,"loading":_vm.loading}})],1)],1)],1),(_vm.canAdd && _vm.tabData === 'users-organization')?_c('VFooter',{attrs:{"app":""}},[_c('div',{staticClass:"py-3 grow"},[_c('VBtn',{staticClass:"mr-2",attrs:{"color":"primary","depressed":""},on:{"click":_vm.onCreate}},[_vm._v("Создать нового")]),_c('VBtn',{staticClass:"mr-2",attrs:{"color":"primary","depressed":""},on:{"click":_vm.onCreateFromDatabase}},[_vm._v("Создать из справочника")])],1)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }